@tailwind base;
@tailwind components;
@tailwind utilities;
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap');

body {
	margin: 0;
	font-family: 'Nunito Sans' !important;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.scrollbar::-webkit-scrollbar {
    width: 10px;
    height: fit-content;
	display: block;
}

.scrollbar::-webkit-scrollbar-track {
    border-radius: 32px;
    background: #E8E8E8;
}

.scrollbar::-webkit-scrollbar-thumb {
    background: #FFAA07;
    border-radius: 32px;
}

.sidebarScrollbar::-webkit-scrollbar {
    width: 8px;
    height: fit-content;
	display: block;
}

.sidebarScrollbar::-webkit-scrollbar-track {
    border-radius: 32px;
    background: white;
}

.sidebarScrollbar::-webkit-scrollbar-thumb {
    background: #ECECEC;
    border-radius: 32px;
}


select {
	-webkit-appearance: none;
	-moz-appearance: none;
	text-indent: 1px;
	text-overflow: '';
}

button:disabled,
input:disabled,
textarea:disabled {
	/* opacity: 0.5; */
	cursor: no-drop;
}

.button-disable {
	background-color: rgba(144, 144, 144, 0.5);
	cursor: no-drop;
}
.text-label {
	--tw-text-opacity: 1;
	color: rgba(144, 144, 144, var(--tw-text-opacity));
}

.fed-card-approve-button {
	position: absolute;
	bottom: -16px;
	left: calc(50% - 8rem);
}
.bg-inactive {
	--tw-bg-opacity: 1;
	background-color: rgba(206, 206, 206, var(--tw-bg-opacity));
}

.bg-medium-yellow {
	--tw-bg-opacity: 1;
	background-color: rgba(255, 225, 168, var(--tw-bg-opacity));
}
.border-new-green {
	--tw-border-opacity: 1;
	border-color: rgba(90, 149, 62, var(--tw-border-opacity));
}

.leading-zero {
	line-height: 0;
}

.border-label {
	--tw-border-opacity: 1;
	border-color: rgba(144, 144, 144, var(--tw-border-opacity));
}
.bg-gold-ornaments-status-border-red {
	--tw-bg-opacity: 1;
	background-color: rgba(255, 120, 89, var(--tw-bg-opacity));
}

.animate-spin {
	-webkit-animation: spin 1s linear infinite;
	animation: spin 1s linear infinite;
}

.non-active-log {
	width: 34px;
	height: 34px;
	line-height: 34px;
	margin-bottom: 8px;
	margin-top: 8px;
}
.active-log {
	width: 48px;
	height: 48px;
	font-size: 22px;
	border-width: 3.5px;
	line-height: calc(48px - 7px);
}
.arrow-head {
	width: 104px;
	margin-top: -5.5rem;
}
.text-xsm {
	font-size: 10px;
}
.bg-red-primary {
	--tw-bg-opacity: 1;
	background-color: rgba(22, 160, 133, var(--tw-bg-opacity));
}

.bg-green-primary {
	--tw-bg-opacity: 1;
	background-color: rgba(22, 160, 133, var(--tw-bg-opacity));
}

.bg-oro-yellow {
	--tw-bg-opacity: 1;
	background-color: rgba(255, 195, 81, var(--tw-bg-opacity));
}

.bg-oro-orange {
	--tw-bg-opacity: 1;
	background-color: rgba(255, 146, 85, var(--tw-bg-opacity));
}

.bg-oro-pink {
	--tw-bg-opacity: 1;
	background-color: rgba(255, 85, 167, var(--tw-bg-opacity));
}

.bg-oro-purple {
	--tw-bg-opacity: 1;
	background-color: rgba(113, 2, 255, var(--tw-bg-opacity));
}

.bg-oro-blue {
	--tw-bg-opacity: 1;
	background-color: rgba(23, 116, 255, var(--tw-bg-opacity));
}

.bg-error-red {
	--tw-bg-opacity: 1;
	background-color: rgba(255, 101, 66, var(--tw-bg-opacity));
}
.text-green-primary {
	--tw-text-opacity: 1;
	color: rgba(22, 160, 133, var(--tw-text-opacity));
}

.text-oro-yellow {
	--tw-text-opacity: 1;
	color: rgba(255, 195, 81, var(--tw-text-opacity));
}

.text-oro-orange {
	--tw-text-opacity: 1;
	color: rgba(255, 146, 85, var(--tw-text-opacity));
}

.text-oro-pink {
	--tw-text-opacity: 1;
	color: rgba(255, 85, 167, var(--tw-text-opacity));
}

.text-oro-purple {
	--tw-text-opacity: 1;
	color: rgba(113, 2, 255, var(--tw-text-opacity));
}

.text-oro-blue {
	--tw-text-opacity: 1;
	color: rgba(23, 116, 255, var(--tw-text-opacity));
}

.text-error-red {
	--tw-text-opacity: 1;
	color: rgba(255, 101, 66, var(--tw-text-opacity));
}

.text-opacity-10 {
	--tw-text-opacity: 0.1;
}

.text-opacity-70 {
	--tw-text-opacity: 0.7;
}
.border-inactive {
	--tw-border-opacity: 1;
	border-color: rgba(206, 206, 206, var(--tw-border-opacity));
}

.border-yellow-primary {
	--tw-border-opacity: 1;
	border-color: rgba(247, 195, 102, var(--tw-border-opacity));
}

.border-oro-yellow {
	--tw-border-opacity: 1;
	border-color: rgba(255, 195, 81, var(--tw-border-opacity));
}

.border-oro-orange {
	--tw-border-opacity: 1;
	border-color: rgba(255, 146, 85, var(--tw-border-opacity));
}

.border-oro-pink {
	--tw-border-opacity: 1;
	border-color: rgba(255, 85, 167, var(--tw-border-opacity));
}

.border-oro-purple {
	--tw-border-opacity: 1;
	border-color: rgba(113, 2, 255, var(--tw-border-opacity));
}

.border-oro-blue {
	--tw-border-opacity: 1;
	border-color: rgba(23, 116, 255, var(--tw-border-opacity));
}

.border-error-red {
	--tw-border-opacity: 1;
	border-color: rgba(255, 101, 66, var(--tw-border-opacity));
}
.scroll-smooth {
	scroll-behavior: smooth;
}
.no-scrollbar::-webkit-scrollbar {
	display: none;
}

.border-flag-red-border {
	--tw-border-opacity: 1;
	border-color: rgba(141, 26, 0, var(--tw-border-opacity));
}

.bg-gold-ornaments-status-background-green {
	--tw-bg-opacity: 1;
	background-color: rgba(246, 255, 253, var(--tw-bg-opacity));
}

.bg-gold-ornaments-status-background-red {
	--tw-bg-opacity: 1;
	background-color: rgba(255, 248, 246, var(--tw-bg-opacity));
}

.bg-gold-ornaments-status-border-red {
	--tw-bg-opacity: 1;
	background-color: rgba(255, 120, 89, var(--tw-bg-opacity));
}

.bg-gradient-to-r {
	background-image: linear-gradient(to right, var(--tw-gradient-stops));
}
.text-gradient {
	background: linear-gradient(to right, #ffe79b, #ffb81e);
	background-clip: text;
	color: transparent;
}

.text-label {
	--tw-text-opacity: 1;
	color: rgba(144, 144, 144, var(--tw-text-opacity));
}
.text-body-bold {
	font-style: normal;
	font-weight: bold !important;
	font-size: 13px;
	line-height: 22px;
}

.text-body-large {
	font-style: normal;
	font-weight: bold;
	font-size: 24px;
	line-height: 33px;
}
.text-body-normal {
	font-style: normal;
	font-weight: normal;
	font-size: 13px;
	line-height: 22px;
}
.text-small-bold {
	font-style: normal;
	font-weight: 800;
	font-size: 12px;
	line-height: 16px;
}
.text-header-3 {
	font-style: normal;
	font-weight: bold;
	font-size: 32px;
	line-height: 44px;
}
.text-new-green {
	--tw-text-opacity: 1;
	color: rgba(90, 149, 62, var(--tw-text-opacity));
}
.equal-sign {
	width: 21px;
	height: 21px;
	line-height: 21px;
	text-align: center;
	background-color: #e7e7e7;
}

.bg-medium-yellow {
	--tw-bg-opacity: 1;
	background-color: rgba(255, 225, 168, var(--tw-bg-opacity));
}

.bg-light-oro-yellow {
	--tw-bg-opacity: 1;
	background-color: rgba(255, 247, 234, var(--tw-bg-opacity));
}

.bg-border-grey {
	--tw-bg-opacity: 1;
	background-color: rgba(230, 230, 230, var(--tw-bg-opacity));
}

.bg-new-green {
	--tw-bg-opacity: 1;
	background-color: rgba(90, 149, 62, var(--tw-bg-opacity));
}

.line{
  top: calc(50% - 80px);
  left: calc(50% - 80px);
  width: calc(100% - 85px);
}

.card {
	--tw-bg-opacity: 1;
	background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
	--tw-border-opacity: 1;
	border-color: rgba(229, 231, 235, var(--tw-border-opacity));
	border-radius: 0.75rem;
	border-width: 1px;
	padding: 1.25rem;
	--tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
		0 4px 6px -2px rgba(0, 0, 0, 0.05);
	box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
		var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
	--tw-bg-opacity: 1;
	background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
	--tw-border-opacity: 1;
	border-color: rgba(229, 231, 235, var(--tw-border-opacity));
	border-radius: 0.75rem;
	border-width: 1px;
	padding: 1.25rem;
	--tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
		0 4px 6px -2px rgba(0, 0, 0, 0.05);
	box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
		var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.card {
	@apply p-5 border border-gray-200 rounded-xl bg-white shadow-lg;
}

.text-ellipsis {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.hide-scrollbar::-webkit-scrollbar {
	width: 0; /* Remove scrollbar space */
	background: transparent; /* Optional: just make scrollbar invisible */
}
.loading-icon {
	animation: rotate 1s linear infinite;
}

@keyframes rotate {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

@-webkit-keyframes spin {
	to {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@keyframes spin {
	to {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

.animate-spin {
	-webkit-animation: spin 1s linear infinite;
	animation: spin 1s linear infinite;
}

.loading-icon {
    animation: rotate 1s linear infinite;
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@layer base {
	input[type='number']::-webkit-outer-spin-button,
	input[type='number']::-webkit-inner-spin-button,
	input[type='number'] {
	  -webkit-appearance: none;
	  margin: 0;
	  -moz-appearance: textfield !important;
	}
 }

 @keyframes spinUp {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

/* Spinner class */
.spinner {
	display: inline-block;
	animation: spinUp 2s linear infinite;
}